<template>
  <div class="w-full relative z-50 inset-0 overflow-y-auto">
    <div
      class="w-full px-8 sm:px-0 flex-col md:items-end justify-center md:min-h-screen pt-16 sm:pt-4 pb-20 text-center sm:block sm:p-0"
    >
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="animate__animated animate__zoomIn animate__faster inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform sm:my-8 sm:align-middle sm:max-w-lg w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 w-full text-center sm:mt-0 sm:text-left">
              <div
                class="sm:text-center sm:px-0 mb-4 flex flex-row items-center justify-between"
              >
                <h3 class="text-lg font-medium leading-6 text-gray-700">
                  {{ title }}
                </h3>
                <button
                  @click="hideModal"
                  type="button"
                  class="transition-all duration-100 ease-linear text-xl hover:text-red-600 font-bold text-gray-400 outline-none"
                >
                  <i class="far fa-times-circle"></i>
                </button>
              </div>
              <slot></slot>
            </div>
          </div>
        </div>
        <div
          class="w-full bg-gray-100 px-4 py-3 md:px-6 sm:flex sm:flex-row sm:items-center sm:justify-end"
        >
          <slot name="action"></slot>
          <button
            @click="hideModal"
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border-2 border-gray-400 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-500 hover:bg-gray-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title"],
  data: () => ({
    loading: false,
    submitted: false,
  }),
  methods: {
    hideModal() {
      this.$emit("hideModal", true);
    },
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
</style>

<template>
  <div v-click-away="away" class="dropdown-wrapper relative" :class="classList">
    <button
      v-on:click="showMenu = !showMenu"
      :class="` text-${color}-800 focus:ring-2 focus:ring-${color}-600 ${buttonClassList}`"
      class="w-full flex justify-between items-center font-semibold py-3 px-4 whitespace-no-wrap rounded transition duration-300"
    >
      <span>{{ dropdownTitle }}</span>
      <i
        v-if="showIcon"
        :class="`fas ${showMenu ? 'fa-chevron-up' : 'fa-chevron-down'} ml-2`"
      ></i>
    </button>
    <transition :name="'fade'">
      <div
        :class="'bg-' + color + '-500 ' + menuClassList"
        class="dropdown-menu text-white mt-1 rounded absolute z-10 shadow-lg max-h-52 overflow-y-auto"
        v-if="showMenu"
      >
        <ul class="list-none overflow-hidden rounded">
          <li v-for="item in items" :key="item">
            <p
              @click="selectItem(item.revenue_item ? item.revenue_item : item)"
              class="flex py-2 px-4 cursor-pointer"
              :class="
                'theme-' +
                color +
                ` transition duration-300 hover:bg-${color}-800`
              "
            >
              {{ item.revenue_item ? item.revenue_item : item }}
            </p>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
export default {
  name: "DropDown",
  props: {
    title: { type: String, required: false },
    items: {
      type: Array,
    },
    classList: { type: Array },
    buttonClassList: { type: Array },
    menuClassList: { type: Array },
    showIcon: { type: Boolean, default: true },
    color: { type: String, default: "blue" },
  },
  emits: ["item-selected"],
  setup(props, { emit }) {
    const showMenu = ref(false);
    const dropdownTitle = ref(
      props.title.length > 0 ? props.title : props.items[0]
    );

    const selectItem = (item) => {
      dropdownTitle.value = item;
      showMenu.value = false;
      emit("item-selected", item);
    };

    const away = () => {
      showMenu.value = false;
    };

    onMounted(() => {
      // console.log(props.title);
    });

    return {
      away,
      showMenu,
      selectItem,
      dropdownTitle,
    };
  },
};
</script>

<style></style>

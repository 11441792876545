<template>
  <div
    class="pt-8 text-gray-dark sec-main-h overflow-y-auto px-4 hide-scrollbar"
  >
    <!-- component -->
    <teleport to=".modal">
      <authorize-modal
        title="Invoice Information"
        v-show="showInvoiceDetailsModal"
        @hideModal="showInvoiceDetailsModal = false"
      >
        <div class="text-left border-t border-gray-100 h-96 overflow-y-auto">
          <dl>
            <div
              class="bg-gray-100 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Name</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ invoiceDetails.customer_name + "" }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Invoice Number</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ invoiceDetails.invoice_number + "" }}
              </dd>
            </div>
            <div
              class="bg-gray-100 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Invoice Status</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ invoiceDetails.status + "" }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Invoice Date</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ formatDate(invoiceDetails.createdAt + "") }}
              </dd>
            </div>
            <hr />
            <h3
              class="text-md font-medium leading-6 text-gray-700 mt-6 mb-1 pl-4 md:pl-0"
            >
              Invoice Items
            </h3>
            <div
              v-for="(item, index) in invoiceDetails.invoice_items"
              :key="item.id"
              :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-white']"
              class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500 mt-1">
                {{ item.item_name }}
              </dt>
              <dd
                class="mt-1 text-md text-gray-600 sm:mt-0 sm:col-span-2 font-medium"
              >
                ₦ {{ formatAmount(item.amount) }}
              </dd>
            </div>
            <div
              class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 bg-white border-t"
            >
              <dt class="text-sm font-medium text-gray-500 mt-1">Total:</dt>
              <dd
                class="text-lg text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                ₦
                {{ totalStampDutyInvoiceAmount(invoiceDetails.invoice_items) }}
              </dd>
            </div>

            <hr />

            <h3
              class="text-md font-medium leading-6 text-gray-700 mt-6 mb-1 pl-4 md:pl-0"
            >
              Invoice receipts
            </h3>
            <div class="px-0 py-2">
              <div class="relative flex">
                <input
                  v-model="invoiceReceiptNumber"
                  @keypress.enter="addReceipt"
                  type="text"
                  id="invoice-receipt"
                  class="rounded-l-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                  placeholder="Receipt number"
                />
                <button
                  @click="addReceipt"
                  class="rounded-r-lg cursor-pointer outline-none border border-gray-300 bg-blue-600 text-white py-2 px-4"
                >
                  Add
                </button>
              </div>

              <ul class="mt-2 w-full">
                <li
                  v-for="(receipt, index) in invoiceReceiptNumbers"
                  :key="receipt"
                  class="w-full flex justify-between items-center bg-gray-50 mb-2 px-4 rounded"
                >
                  <span class="text-sm text-gray-600">{{ receipt }}</span>
                  <span
                    @click="removeReceipt(index)"
                    class="font-semibold text-red-500 text-lg"
                    ><i class="fas fa-minus-circle"></i
                  ></span>
                </li>
              </ul>
            </div>
          </dl>
        </div>
        <template #action>
          <button
            v-if="invoiceDetails.status !== 'paid'"
            @click.prevent="reprintInvoice"
            type="button"
            class="py-2 px-4 flex justify-center items-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
          >
            <div v-if="!reprintingInvoice">Reprint</div>
            <div v-else class="flex items-center justify-center">
              <svg
                width="20"
                height="20"
                fill="currentColor"
                class="mr-2 animate-spin"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
                ></path>
              </svg>
              loading
            </div>
          </button>
          <button
            v-if="invoiceDetails.status !== 'paid'"
            @click.prevent="updateInvoice"
            type="button"
            class="py-2 px-4 ml-4 flex justify-center items-center bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
          >
            <div v-if="!updatingInvoice">Update</div>
            <div v-else class="flex items-center justify-center">
              <svg
                width="20"
                height="20"
                fill="currentColor"
                class="mr-2 animate-spin"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
                ></path>
              </svg>
              loading
            </div>
          </button>
        </template>
      </authorize-modal>
    </teleport>

    <div
      class="bg-gradient-to-r from-blue-primary to-blue-600 sm:h-50 p-8 rounded-t"
    >
      <div class="sm:container mx-auto">
        <nav class="flex items-center mb-4">
          <!-- <a
            class="no-underline text-white px-4 font-medium mr-3 bg-indigo hover:bg-indigo-darker"
            href="#"
            >Cardamom</a
          > -->

          <div class="flex items-center gap-2 sm:gap-8 text-white">
            <!-- <label class="inline-flex items-center">
              <input type="radio" name="vehicle" class="h-5 w-5 text-red-600" />
              <span class="ml-2 ">
                Date created
              </span>
            </label>
            <label class="inline-flex items-center">
              <input type="radio" name="vehicle" class="h-5 w-5 text-red-600" />
              <span class="ml-2 ">
                Assessment Year
              </span>
            </label>
            <label class="inline-flex items-center">
              <input type="radio" name="vehicle" class="h-5 w-5 text-red-600" />
              <span class="ml-2 ">
                Both
              </span>
            </label> -->
            <div
              @click="dateRangeFilter = !dateRangeFilter"
              class="flex items-center space-x-2 cursor-pointer"
            >
              <input
                type="checkbox"
                name="date_range_filter"
                v-model="dateRangeFilter"
                class="focus:ring cursor-pointer rounded-lg"
              />
              <span class="text-white font-medium rounded text-sm"
                >Date created</span
              >
            </div>
            <!-- <div
              @click="stampDutyYearFilter = !stampDutyYearFilter"
              class="flex items-center space-x-2 cursor-pointer"
            >
              <input
                type="checkbox"
                name="stampDutyYear_filter"
                v-model="stampDutyYearFilter"
                class="focus:ring cursor-pointer rounded-lg"
              />
              <span class="text-white font-medium rounded text-sm"
                >Assessment Year</span
              >
            </div> -->
          </div>
        </nav>
        <div
          class="flex flex-col sm:flex-row sm:items-center rounded sm:h-12 sm:bg-white w-full"
        >
          <!-- <drop-down
            :items="stampDutyStatus"
            :title="'All'"
            :showIcon="true"
            @item-selected="selectStampDutyStatus"
            :classList="[
              ' w-full sm:w-32 hidden  border-b-2 border-blue-600 sm:border-white bg-white rounded mb-2 sm:mb-0 sm:rounded-none sm:border-none',
            ]"
          ></drop-down> -->
          <drop-down
            :items="stampDutyFilter"
            :title="filter.item"
            :showIcon="true"
            @item-selected="selectStampDutyFilter"
            :buttonClassList="['sm:w-36']"
            :menuClassList="['sm:w-36']"
            :classList="[
              ' w-full sm:w-36 border-b-2 border-blue-600 sm:border-white bg-white rounded mb-2 sm:mb-0 sm:rounded-none sm:border-none',
            ]"
          ></drop-down>
          <drop-down
            v-if="filter.item.toLowerCase() == 'status'"
            :items="stampDutyStatusFilter"
            :title="filter.status"
            :showIcon="true"
            @item-selected="selectStampDutyStatus"
            :buttonClassList="['sm:w-full capitalize']"
            :menuClassList="['sm:w-full capitalize']"
            :classList="[
              ' w-full border-2 border-blue-600  bg-white rounded mb-2 sm:mb-0 sm:rounded-none ',
            ]"
          ></drop-down>

          <input
            v-else
            @keyup.enter="search"
            class="w-full h-10 sm:h-12 mt-1 sm:mt-0 rounded focus:outline-none focus:shadow-outline md:text-xl px-4 shadow-lg sm:border-l-2 sm:border-blue-primary-dark"
            type="search"
            v-model="filter.value"
            :placeholder="`Please enter ${filter.item}...`"
          />
          <button
            @click="search"
            class="text-white flex items-center rounded sm:rounded-none sm:flex-none w-full mt-4 sm:mt-0 sm:w-10 bg-green-500 h-10 sm:h-12 px-2 focus:outline-none hover:ring-2 hover:ring-primary-dark"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 hidden sm:block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span
              class="block sm:hidden w-full text-center text-lg font-medium"
            >
              <div v-if="!fetchingStampDuties">Search</div>
              <div v-else class="flex items-center space-x-4 justify-center">
                <i class="fas fa-circle-notch animate-spin"></i>
                <span>Please wait...</span>
              </div>
            </span>
          </button>
        </div>

        <div
          class="flex flex-col sm:flex-row sm:items-end sm:space-x-2 text-white mt-4 sm:mt-6 sm:order-first sm:mr-10"
        >
          <template v-if="dateRangeFilter">
            <date-picker
              :classList="['text-white text-sm sm:text-md']"
              :datePickerClass="['text-primary']"
              :newDate="selectedDates.startDate"
              :title="'Start Date'"
              @updateDate="updateStartDate"
            ></date-picker>
            <date-picker
              :classList="['text-white text-sm sm:text-md']"
              :datePickerClass="['text-primary ml-1.5 sm:ml-0']"
              :newDate="selectedDates.endDate"
              :title="'End Date'"
              @updateDate="updateEndDate"
            ></date-picker>
          </template>

          <!-- <div
            v-if="stampDutyYearFilter"
            class="relative flex flex-row items-center justify-between mt-2 w-full sm:w-auto"
          >
            <label
              for="stampDutyYear"
              class="leading-relaxed md:font-semibold text-md sm:text-lg mr-6 sm:mr-2 text-white"
              >Year :</label
            >
            <input
              type="number"
              id="stampDutyYear"
              name="stampDutyYear"
              v-model="filter.stampDutyYear"
              autocomplete="off"
              class="w-20 bg-white rounded border border-gray-300 focus:border-blue-primary focus:ring-2 focus:ring-blue-primary text-base outline-none text-primary font-medium py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div> -->
        </div>
      </div>
    </div>

    <div class="-my-2 py-2 h-5/6 sm:h-3/4 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div
        v-if="fetchingStampDuties"
        class="w-full mt-2 flex items-center text-center"
      >
        <i
          :class="`lg:focus:shadow-sm hidden sm:block w-12 mx-auto text-center fas fa-circle-notch mt-4 text-5xl text-blue-primary animate-spin`"
        ></i>
      </div>
      <div
        v-else
        style="width: 90%"
        class="align-middle inline-block min-w-full shadow overflow-hidden bg-white shadow-dashboard pt-3 rounded-bl-lg rounded-br-lg"
      >
        <div class="overflow-x-auto w-full">
          <table class="min-w-full table-striped">
            <thead>
              <tr class="text-xs">
                <th
                  class="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-700 tracking-wider"
                >
                  TIN
                </th>
                <th
                  class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider"
                >
                  Assignor
                </th>
                <th
                  class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider"
                >
                  Type
                </th>
                <th
                  class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider"
                >
                  Date
                </th>

                <th
                  class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider"
                >
                  Rate
                </th>

                <th
                  class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider"
                >
                  Amount
                </th>
                <th
                  class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider"
                >
                  Penalty
                </th>
                <th
                  class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider"
                >
                  Total
                </th>
                <th
                  class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider"
                >
                  Invoice No
                </th>
                <th
                  class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider"
                >
                  Status
                </th>
                <th
                  class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr
                class="cursor-pointer hover:bg-gray-100 hover:font-semibold"
                v-for="stampDuty in stampDuties"
                :key="stampDuty.id"
              >
                <td
                  class="px-6 whitespace-no-wrap border-b-2 border-dashed border-gray-200"
                >
                  <div class="text-sm leading-5 text-gray-800 py-4">
                    {{ stampDuty.assignee.tin }}
                  </div>
                </td>
                <td
                  class="px-6 whitespace-no-wrap border-b-2 border-dashed border-gray-200"
                >
                  <div class="text-sm leading-5 text-gray-700 w-56 sm:w-54">
                    {{ stampDuty.assignor.name }}
                  </div>
                </td>
                <td
                  class="px-6 whitespace-no-wrap border-b-2 border-dashed border-gray-200"
                >
                  <div class="text-sm leading-5 text-gray-700 w-56 sm:w-40">
                    {{ stampDuty.rate.name }}
                  </div>
                </td>
                <td
                  class="px-6 w-36 whitespace-no-wrap border-b-2 border-dashed border-gray-200 text-gray-700 text-sm leading-5"
                >
                  <div class="text-sm leading-5 text-gray-700 w-44 sm:w-34">
                    {{ formatDate(stampDuty.createdAt) }}
                  </div>
                </td>
                <td
                  class="px-6 whitespace-no-wrap border-b-2 border-dashed border-gray-200 text-gray-700 text-sm leading-5"
                >
                  <div class="text-sm leading-5 text-gray-700 w-32 sm:w-32">
                    {{ formatStampDutyRate(stampDuty.rate) }}
                  </div>
                </td>

                <td
                  class="px-6 w-32 md:w-28 whitespace-no-wrap border-b-2 border-dashed border-gray-200 text-gray-700 text-sm leading-5"
                >
                  <div class="text-sm leading-5 text-gray-700 w-32 md:w-28">
                    ₦ {{ formatAmount(stampDuty.total) }}
                  </div>
                </td>
                <td
                  class="px-6 w-32 md:w-28 whitespace-no-wrap border-b-2 border-dashed border-gray-200 text-gray-700 text-sm leading-5"
                >
                  <div class="text-sm leading-5 text-gray-700 w-32 md:w-28">
                    ₦ {{ formatAmount(stampDuty.penalty) }}
                  </div>
                </td>
                <td
                  class="px-6 w-32 md:w-28 whitespace-no-wrap border-b-2 border-dashed border-gray-200 text-gray-700 text-sm leading-5"
                >
                  <div class="text-sm leading-5 text-gray-700 w-32 md:w-28">
                    ₦
                    {{
                      totalStampDutyAmount(stampDuty.total, stampDuty.penalty)
                    }}
                  </div>
                </td>

                <td
                  class="px-6 whitespace-no-wrap border-b-2 border-dashed border-gray-200 text-gray-700 text-sm leading-5"
                >
                  <button
                    @click="showInvoiceDetails(stampDuty.invoice_number)"
                    class="outline-none underline cursor-pointer hover:font-medium"
                  >
                    {{ stampDuty.invoice_number }}
                  </button>
                </td>
                <td
                  class="px-6 whitespace-no-wrap border-b-2 border-dashed border-gray-200 text-gray-700 text-sm leading-5"
                >
                  <span
                    :class="`w-24 relative inline-block px-4 py-1 pb-2  text-center rounded-full font-semibold leading-tight ${
                      stampDuty.status === 'authorized'
                        ? ' bg-green-200 text-green-700 '
                        : stampDuty.status === 'verified'
                        ? ' bg-yellow-200 text-yellow-700 '
                        : stampDuty.status === 'draft'
                        ? 'bg-gray-200 text-gray-700'
                        : ' bg-indigo-200 text-indigo-700 '
                    } `"
                  >
                    <span
                      aria-hidden
                      class="absolute inset-0 opacity-50"
                    ></span>
                    <span class="relative text-xs">{{ stampDuty.status }}</span>
                  </span>
                </td>

                <td
                  class="px-6 whitespace-no-wrap border-b-2 border-dashed border-gray-200 text-left text-sm leading-5"
                >
                  <button
                    @click.prevent.stop="printBill(stampDuty)"
                    v-if="stampDuty.status !== 'draft'"
                    :disabled="
                      stampDuty.status.toLowerCase() !== 'authorized' ||
                      preparingNotice
                        ? true
                        : false
                    "
                    class="w-20 flex items-center justify-center px-2 py-1 rounded transition duration-300 focus:outline-none border-2"
                    :class="`${
                      stampDuty.status.toLowerCase() !== 'authorized'
                        ? 'border-gray-300 text-gray-300'
                        : 'border-blue-500  text-blue-500 hover:bg-blue-700 hover:text-white'
                    }`"
                  >
                    <svg
                      v-if="stampDutyId !== stampDuty.id"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <i
                      v-else
                      :class="`lg:focus:shadow-sm w-5 text-center fas fa-circle-notch text-lg animate-spin`"
                    ></i>
                  </button>
                  <button
                    v-if="stampDuty.status === 'draft'"
                    @click.prevent="clearStampDuty(stampDuty.id)"
                    class="w-20 py-1 cursor-pointer font-semibold rounded transition duration-300 outline-none border-2 border-blue-500 text-blue-500 hover:bg-blue-700 hover:text-white"
                  >
                    <span v-if="stampDutyId !== stampDuty.id">Process</span>

                    <i
                      v-else
                      :class="`lg:focus:shadow-sm w-4 text-center fas fa-circle-notch text-lg animate-spin`"
                    ></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!--        <div-->
        <!--          class="sm:flex-1 sm:flex sm:items-center sm:justify-between mt-4 work-sans"-->
        <!--        >-->
        <!--          <div>-->
        <!--            <p class="text-sm leading-5 text-blue-700">-->
        <!--              Showing-->
        <!--              <span class="font-medium">1</span>-->
        <!--              to-->
        <!--              <span class="font-medium">200</span>-->
        <!--              of-->
        <!--              <span class="font-medium">2000</span>-->
        <!--              results-->
        <!--            </p>-->
        <!--          </div>-->
        <!--          <div>-->
        <!--            <nav class="relative z-0 inline-flex shadow-sm">-->
        <!--              <div>-->
        <!--                <a-->
        <!--                  href="#"-->
        <!--                  class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"-->
        <!--                  aria-label="Previous"-->
        <!--                  v-on:click.prevent="changePage(pagination.current_page - 1)"-->
        <!--                >-->
        <!--                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">-->
        <!--                    <path-->
        <!--                      fill-rule="evenodd"-->
        <!--                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"-->
        <!--                      clip-rule="evenodd"-->
        <!--                    />-->
        <!--                  </svg>-->
        <!--                </a>-->
        <!--              </div>-->
        <!--              <div>-->
        <!--                <a-->
        <!--                  href="#"-->
        <!--                  class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-blue-700 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-tertiary active:text-gray-700 transition ease-in-out duration-150 hover:bg-tertiary"-->
        <!--                >-->
        <!--                  1-->
        <!--                </a>-->
        <!--                <a-->
        <!--                  href="#"-->
        <!--                  class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-blue-600 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-tertiary active:text-gray-700 transition ease-in-out duration-150 hover:bg-tertiary"-->
        <!--                >-->
        <!--                  2-->
        <!--                </a>-->
        <!--                <a-->
        <!--                  href="#"-->
        <!--                  class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-blue-600 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-tertiary active:text-gray-700 transition ease-in-out duration-150 hover:bg-tertiary"-->
        <!--                >-->
        <!--                  3-->
        <!--                </a>-->
        <!--              </div>-->
        <!--              <div v-if="pagination.current_page < pagination.last_page">-->
        <!--                <a-->
        <!--                  href="#"-->
        <!--                  class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"-->
        <!--                  aria-label="Next"-->
        <!--                >-->
        <!--                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">-->
        <!--                    <path-->
        <!--                      fill-rule="evenodd"-->
        <!--                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"-->
        <!--                      clip-rule="evenodd"-->
        <!--                    />-->
        <!--                  </svg>-->
        <!--                </a>-->
        <!--              </div>-->
        <!--            </nav>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed } from "vue";
import AuthorizeModal from "@/components/AuthorizeModal.vue";
import DatePicker from "@/components/DatePicker2.vue";
import DropDown from "../shared/DropDown";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import numeral from "numeral";
export default {
  name: "SearchStampDuty",
  components: {
    DatePicker,
    DropDown,
    AuthorizeModal,
  },
  setup() {
    const selectedDates = ref({
      startDate: new Date(),
      endDate: new Date(),
    });
    const dateRangeFilter = ref(false);
    const updatingInvoice = ref(false);
    const reprintingInvoice = ref(false);
    const showInvoiceDetailsModal = ref(false);
    const store = useStore();
    const stampDutyYearFilter = ref(false);
    const stampDutyYear = ref(new Date().getFullYear());
    const stampDutyStatus = ref(["All", "Pending", "Verified", "Authorized"]);
    const stampDutyFilter = ref(["TIN", "Invoice No.", "Cert No.", "Status"]);
    const stampDutyStatusFilter = ref([
      "all",
      "draft",
      "pending",
      "verified",
      "approved",
      "authorized",
    ]);
    const fetchingStampDuties = ref(false);
    const invoiceDetails = ref({});
    const filter = reactive({
      start_date: selectedDates.value.startDate,
      end_date: selectedDates.value.endDate,
      stampDutyYear: new Date().getFullYear(),
      status: stampDutyStatus.value[0],
      item: stampDutyFilter.value[0],
      value: "",
    });
    const loadingData = ref(false);
    const preparingNotice = ref(false);
    const stampDutyId = ref(0);
    const invoiceReceiptNumbers = ref([]);
    const invoiceReceiptNumber = ref("");
    // const pdfData = ref(null);

    //computed properties
    const stampDuties = computed(() => store.getters["stamp_duty/duties"]);

    function formatAmount(amt) {
      return numeral(amt).format("0,0.00");
    }
    function formatDate(dt) {
      return dayjs(dt).format("DD-MMM-YYYY h:mm:ss a");
    }
    function totalStampDutyAmount(rate, penalty) {
      return numeral(parseFloat(rate) + parseFloat(penalty)).format("0,0.00");
    }
    function totalStampDutyInvoiceAmount(invoice = []) {
      let total = 0;
      invoice.forEach((item) => {
        total += parseFloat(item.amount);
      });

      return numeral(total).format("0,0.00");
    }
    function formatStampDutyRate(duty) {
      return `${duty.type == "flat" ? "N" : ""} ${
        duty.type == "flat" ? formatAmount(duty.rate) : duty.rate
      }  ${duty.type == "flat" ? "" : "%"}`;
    }
    function updateStartDate(newDate) {
      selectedDates.value.startDate = newDate;
      filter.start_date = newDate;
    }
    function updateEndDate(newDate) {
      selectedDates.value.endDate = newDate;
      filter.end_date = newDate;
    }
    function selectStampDutyStatus(status) {
      filter.status = status;
    }
    function selectStampDutyFilter(item) {
      if (item == "Invoice No.") filter.item = "invoice_number";
      if (item == "Cert No.") filter.item = "certificate_id";
      if (item == "TIN") filter.item = "tin";
      else filter.item = item;
    }
    function addReceipt() {
      if (invoiceReceiptNumber.value != "") {
        invoiceReceiptNumbers.value.push(invoiceReceiptNumber.value);
        invoiceReceiptNumber.value = "";
      }
    }
    function removeReceipt(index) {
      invoiceReceiptNumbers.value.splice(index, 1);
    }
    async function reprintInvoice() {
      try {
        reprintingInvoice.value = true;
        const { status, msg, data } = await store.dispatch(
          "invoice/reprintInvoice",
          invoiceDetails.value.invoice_number
        );

        if (status != "error") {
          await window.open(
            URL.createObjectURL(new Blob([data], { type: "application/pdf" })),
            "_blank"
          );
        } else {
          Swal.fire({
            title: "Revenue House",
            text: msg,
            icon: "error",
          });
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Revenue House",
          text: error,
          icon: "error",
        });
      } finally {
        reprintingInvoice.value = false;
      }
    }
    async function printBill(stampDuty) {
      try {
        if (stampDuty.status === "authorized") {
          stampDutyId.value = stampDuty.id;
          preparingNotice.value = true;
          const result = await store.dispatch(
            "stamp_duty/getStampDutyCertificate",
            stampDuty.certificate_number
          );

          await window.open(
            URL.createObjectURL(
              new Blob([result.data], { type: "application/pdf" })
            ),
            "_blank"
          );
        } else {
          Swal.fire({
            title: "Revenue House",
            text: "Stamp Duty pending approval, please try again later...",
            icon: "warning",
          });
        }
      } catch (e) {
        console.log(e);
        Swal.fire({
          title: "Revenue House",
          text: e,
          icon: "error",
        });
      } finally {
        preparingNotice.value = false;
        stampDutyId.value = 0;
      }
    }

    async function search() {
      try {
        fetchingStampDuties.value = true;
        filter.start_date = dayjs(filter.start_date).format("YYYY-MM-DD");
        filter.end_date = dayjs(filter.end_date).format("YYYY-MM-DD");

        if (!dateRangeFilter.value) {
          delete filter.start_date;
          delete filter.end_date;
        }

        if (!stampDutyYearFilter.value) delete filter.stampDutyYear;

        if (filter.value === "" && filter.item.toLowerCase() !== "status") {
          Swal.fire({
            title: "Revenue House",
            text: `Please provide ${filter.item}`,
            icon: "error",
          });
        } else
          await store.dispatch("stamp_duty/getDuties", {
            ...filter,
            from: "search",
          });
      } catch (e) {
        console.log(e);
        Swal.fire({
          title: "Revenue House",
          text: e,
          icon: "error",
        });
      } finally {
        fetchingStampDuties.value = false;
      }
    }

    async function showInvoiceDetails(invoice) {
      try {
        Swal.showLoading();

        await store.dispatch("invoice/getInvoice", invoice);
        invoiceDetails.value = store.getters["invoice/invoices"][0];
        showInvoiceDetailsModal.value = true;
      } catch (error) {
        console.log(error);
      } finally {
        Swal.close();
      }
    }
    async function clearStampDuty(id) {
      try {
        stampDutyId.value = id;

        await store.dispatch("stamp_duty/completeStampDutyForVerification", id);

        Swal.fire({
          title: "Revenue House",
          text: "Stamp duty status updated!",
          icon: "success",
        });
        await search();
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Revenue House",
          text: error,
          icon: "error",
        });
      } finally {
        stampDutyId.value = 0;
      }
    }
    async function updateInvoice() {
      try {
        if (invoiceReceiptNumbers.value.length > 0) {
          updatingInvoice.value = true;

          await store.dispatch("invoice/updateInvoice", {
            id: invoiceDetails.value.invoice_number,
            receipts: invoiceReceiptNumbers.value,
          });

          showInvoiceDetailsModal.value = false;
          Swal.fire({
            title: "Revenue House",
            text: "Stamp duty status updated!",
            icon: "success",
          });
          await search();
        } else {
          Swal.fire({
            title: "Revenue House",
            text: "Please provide payment receipt numbers",
            icon: "warning",
          });
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Revenue House",
          text: error,
          icon: "error",
        });
      } finally {
        updatingInvoice.value = false;
      }
    }

    return {
      loadingData,
      invoiceDetails,
      stampDuties,
      formatAmount,
      formatDate,
      formatStampDutyRate,
      totalStampDutyAmount,
      totalStampDutyInvoiceAmount,
      updateInvoice,
      showInvoiceDetails,
      search,
      selectedDates,
      fetchingStampDuties,
      stampDutyStatus,
      stampDutyFilter,
      selectStampDutyStatus,
      selectStampDutyFilter,
      stampDutyStatusFilter,
      invoiceReceiptNumbers,
      invoiceReceiptNumber,
      dateRangeFilter,
      stampDutyYear,
      stampDutyYearFilter,
      showInvoiceDetailsModal,
      updateStartDate,
      updateEndDate,
      updatingInvoice,
      reprintingInvoice,
      clearStampDuty,
      filter,
      addReceipt,
      removeReceipt,
      printBill,
      reprintInvoice,
      preparingNotice,
      stampDutyId,
    };
  },
};
</script>

<style scoped></style>

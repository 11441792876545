<template>
  <div class="p-6 w-full">
    <div class="flex flex-col sm:flex-row">
      <div
        class="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 sm:pr-4 relative mb-4"
        :class="{ 'mb-6': selectedDutyRate.rate_type != 'advalorem' }"
      >
        <div class="flex items-center justify-between">
          <label for="name" class="leading-7 text-sm text-gray-600"
            >Item name</label
          >
          <button
            id="btnClear"
            type="button"
            @click="resetForm"
            class="pl-8 py-1 font-semibold text-red-400 text-xs focus:outline-none"
          >
            reset
          </button>
        </div>

        <rates-drop-down
          :items="duty_rates"
          :title="'Select Duty rate'"
          :color="'gray'"
          :classList="[
            ' w-full rounded border-2 focus:border-none border-gray-300 text-gray-700',
          ]"
          @item-selected="selectDutyRate"
        ></rates-drop-down>
      </div>
      <div
        class="w-full flex sm:flex-row flex-col space-y-2 space-x-0 sm:space-y-0 sm:space-x-3 sm:pl-2 overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2"
      >
        <div>
          <label for="name" class="leading-7 text-sm text-gray-600"
            >Enter amount</label
          >

          <input
            type="number"
            id="name"
            name="name"
            :disabled="selectedDutyRate.rate_type === 'flat'"
            v-model="duty_details.instrument_value"
            :placeholder="
              selectedDutyRate.rate_type !== 'flat'
                ? 'Please enter amount'
                : 'Amount is fixed for this item'
            "
            autocomplete="off"
            :class="[
              selectedDutyRate.rate_type !== 'flat'
                ? 'text-gray-600'
                : 'text-gray-300',
            ]"
            class="w-full bg-white rounded border-2 border-gray-300 focus:border-gray-600 focus:ring-1 focus:ring-gray-600 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          />
        </div>
        <div>
          <label for="name" class="leading-7 text-sm text-gray-600"
            >Enter Penalty</label
          >
          <input
            type="number"
            id="name"
            name="name"
            v-model="duty_details.penalty"
            placeholder="Please enter penalty"
            autocomplete="off"
            class="w-full bg-white rounded border-2 text-gray-600 border-gray-300 focus:border-gray-600 focus:ring-1 focus:ring-gray-600 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          />
        </div>
      </div>
    </div>
    <div
      :class="[
        selectedDutyRate.rate_type != 'advalorem' ? 'sm:pr-0' : 'mt-8 sm:mt-0',
      ]"
    >
      <div
        class="transition duration-200 ease-linear mb-12 w-full bg-gray-100 pl-4 py-4 text-gray-500 font-medium sm:mt-2 shadow-sm"
      >
        Tax payable : ₦
        {{
          formatAmount(
            parseFloat(
              selectedDutyRate.rate_type == "advalorem"
                ? dutyTaxPayable
                : selectedDutyRate.rate
            ) +
              parseFloat(duty_details.penalty == "" ? 0 : duty_details.penalty)
          )
        }}
        <span v-show="selectedDutyRate.rate_type == 'advalorem'"
          >at {{ selectedDutyRate.rate || 0 }}%</span
        >
        <span v-show="duty_details.penalty != ''" class="ml-1"
          >with penalties.</span
        >
      </div>
    </div>
    <!-- <div class=" border-b-2 border-solid pb-12"></div> -->
    <div>
      <p
        class="text-gray-600 font-semibold border-b-2 border-solid border-gray-100 w-full mb-6 pb-2"
      >
        Assignee
      </p>

      <div class="flex flex-wrap -mx-3 overflow-hidden pb-6">
        <div
          class="my-3 px-3 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2"
        >
          <label for="name" class="leading-7 text-sm text-gray-600">TIN.</label>
          <input
            type="text"
            v-model="duty_details.assignee.tin"
            @change="verifyTin"
            @blur="verifyTin"
            autocomplete="off"
            class="w-full bg-white rounded border-2 border-gray-300 focus:border-gray-600 focus:ring-1 focus:ring-gray-600 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          />
          <span
            v-if="!tinValid && !verifyingTin"
            class="text-red-400 text-xs font-semibold"
            >Tin not valid.</span
          >
          <span v-if="verifyingTin" class="text-gray-400 text-xs font-semibold"
            >Verifying TIN, please wait...</span
          >
        </div>
        <div
          class="my-3 px-3 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2"
        >
          <p class="leading-7 text-sm text-gray-600 md:pt-5">Name:</p>
          {{ tinDetails.name }}
        </div>
        <div
          class="my-3 px-3 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 hidden"
        >
          <label for="name" class="leading-7 text-sm text-gray-600"
            >Receipt number</label
          >
          <input
            type="text"
            v-model="duty_details.receipt_number"
            autocomplete="off"
            class="w-full bg-white rounded border-2 border-gray-300 focus:border-gray-600 focus:ring-1 focus:ring-gray-600 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
          />
        </div>
      </div>
    </div>

    <div class="flex flex-wrap -mx-3 overflow-hidden pb-12">
      <p
        class="text-gray-600 font-semibold border-b-2 border-solid border-gray-100 w-full mx-3 mb-6 mt-12 pb-2"
      >
        Assignor details
      </p>
      <div
        class="my-3 px-3 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2"
      >
        <label for="name" class="leading-7 text-sm text-gray-600">Name</label>
        <input
          type="text"
          v-model="duty_details.assignor.name"
          autocomplete="off"
          class="w-full bg-white rounded border-2 border-gray-300 focus:border-gray-600 focus:ring-1 focus:ring-gray-600 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <div
        class="my-3 px-3 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2"
      >
        <label for="name" class="leading-7 text-sm text-gray-600"
          >Email address</label
        >
        <input
          type="email"
          v-model="duty_details.assignor.email"
          autocomplete="off"
          class="w-full bg-white rounded border-2 border-gray-300 focus:border-gray-600 focus:ring-1 focus:ring-gray-600 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <div
        class="my-3 px-3 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2"
      >
        <label for="name" class="leading-7 text-sm text-gray-600">Phone</label>
        <input
          type="phone"
          v-model="duty_details.assignor.phone"
          autocomplete="off"
          class="w-full bg-white rounded border-2 border-gray-300 focus:border-gray-600 focus:ring-1 focus:ring-gray-600 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <div
        class="my-3 px-3 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2"
      >
        <label for="name" class="leading-7 text-sm text-gray-600"
          >Address</label
        >
        <input
          type="text"
          v-model="duty_details.assignor.address"
          autocomplete="off"
          class="w-full bg-white rounded border-2 border-gray-300 focus:border-gray-600 focus:ring-1 focus:ring-gray-600 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
    </div>

    <div
      class="flex flex-col sm:flex-row items-center sm:space-x-4 w-full sm:pt-3 sm:justify-end"
    >
      <button
        id="btnAddAudit"
        type="button"
        @click="addDuty"
        class="w-full px-8 py-2 mb-4 sm:mb-0 font-semibold text-white text-lg sm:text-md transition duration-500 ease-in-out transform rounded-lg shadow-md bg-gradient-to-r from-green-700 hover:from-green-600 to-green-600 hover:to-green-700 focus:ring focus:ring-green-700 focus:outline-none"
      >
        <div v-if="!creatingStampDutyRequest">Save</div>
        <div v-else class="flex items-center space-x-4 justify-center">
          <i class="fas fa-circle-notch animate-spin"></i>
          <span>Please wait...</span>
        </div>
      </button>
      <!-- <button
        id="btnEditAudit"
        type="button"
        class="w-full sm:w-1/2 px-8 py-2 font-semibold text-white text-lg sm:text-md transition duration-500 ease-in-out transform rounded-lg shadow-md bg-blue-primary hover:bg-blue-primary-dark focus:ring focus:ring-blue-700 focus:outline-none"
      >
        <div v-if="true">Update</div>
        <div v-else class="flex items-center space-x-4 justify-center">
          <i class="fas fa-circle-notch animate-spin"></i>
          <span>Please wait...</span>
        </div>
      </button> -->
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { useStore } from "vuex";
import { computed } from "vue";
import RatesDropDown from "./RatesDropDown.vue";
import numeral from "numeral";
import { Axios } from "../../helpers/axios-config";
export default {
  components: { RatesDropDown },
  data: () => ({
    selectedDutyRate: {},
    duty_details: {
      assignee: {
        tin: "",
      },
      assignor: {
        name: "",
        email: "",
        phone: "",
        address: "",
      },
      rate_id: "",
      receipt_number: "",
      instrument_value: 0,
      penalty: 0,
    },
    tinDetails: {},
    creatingStampDutyRequest: false,
    verifyingTin: false,
    tinValid: true,
    duty_rate_amount: 0,
  }),
  computed: {
    dutyTaxPayable() {
      return (
        (parseFloat(this.selectedDutyRate ? this.selectedDutyRate.rate : 0) *
          this.duty_details.instrument_value) /
        100
      );
    },
    selectedDutyRateType() {
      return this.selectedDutyRate.rate_type;
    },
  },
  methods: {
    selectDutyRate(rate) {
      this.selectedDutyRate = rate;
      this.duty_details.rate_id = rate.id;

      if (rate.rate_type === "flat") {
        this.duty_details.instrument_value = parseFloat(rate.rate);
      } else {
        this.duty_details.instrument_value = 0;
      }
    },
    async verifyTin() {
      try {
        this.verifyingTin = true;
        let result = await Axios.get(
          `taxpayer/${this.duty_details.assignee.tin}`
        );
        if (result.data.status !== "error") {
          this.tinValid = true;
          this.tinDetails = result.data;
        } else {
          this.tinValid = false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.verifyingTin = false;
      }
    },
    resetForm() {
      this.duty_details = {
        assignee: {
          tin: "",
        },
        assignor: {
          name: "",
          email: "",
          phone: "",
          address: "",
        },
        rate_id: "",
        receipt_number: "",
        instrument_value: 0,
        penalty: 0,
      };
      this.tinDetails = {};
    },
    async addDuty() {
      if (!this.selectedDutyRate.rate) {
        Swal.fire({
          title: "Revenue House",
          text: "Please select duty type",
          icon: "error",
        });
      } else if (
        this.selectedDutyRate.rate_type == "advalorem" &&
        this.duty_details.instrument_value == ""
      ) {
        Swal.fire({
          title: "Revenue House",
          text: "Please provide instrument value",
          icon: "error",
        });
      } else if (!this.tinValid || this.duty_details.assignee.tin === "") {
        Swal.fire({
          title: "Revenue House",
          text: "Please provide a valid TIN",
          icon: "error",
        });
      } else if (this.duty_details.receipt_number !== "") {
        Swal.fire({
          title: "Revenue House",
          text: "Please provide receipt number",
          icon: "error",
        });
      } else {
        try {
          this.creatingStampDutyRequest = true;
          delete this.duty_details.receipt_number;
          const { status, msg, data } = await this.$store.dispatch(
            "stamp_duty/addStampDuty",
            this.duty_details
          );

          if (status != "error") {
            Swal.fire({
              title: "Revenue House",
              text: "Duty added successfully",
              icon: "success",
            });
            await window.open(
              URL.createObjectURL(
                new Blob([data], { type: "application/pdf" })
              ),
              "_blank"
            );
            this.resetForm();
          } else {
            Swal.fire({
              title: "Revenue House",
              text: msg,
              icon: "error",
            });
          }
        } catch (error) {
          console.log(error);
          Swal.fire({
            title: "Revenue House",
            text: error,
            icon: "error",
          });
        } finally {
          this.creatingStampDutyRequest = false;
        }
      }
    },
  },
  setup() {
    const store = useStore();

    const duty_rates = computed(() => store.getters["stamp_duty/duty_rates"]);

    function formatAmount(amt) {
      return numeral(amt).format("0,0.00");
    }

    return {
      duty_rates,
      formatAmount,
    };
  },
  created() {
    this.$store.dispatch("stamp_duty/getDutyRates");
  },
};
</script>

<style></style>

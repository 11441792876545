<template>
  <content-holder class="main-h overflow-y-scroll px-2" :title="''">
    <div v-if="loadingData" class="w-full mt-32 flex items-center text-center">
      <i
        :class="`lg:focus:shadow-sm w-12 mx-auto text-center fas fa-circle-notch mt-4 text-5xl text-blue-primary animate-spin`"
      ></i>
    </div>
    <div v-else-if="!loadingData && stampDutyRequests.length < 1">
      <p
        class="flex items-center transition text-center text-white bg-blue-primary font-medium text-md md:text-lg w-full mb-2 py-4 rounded-md"
      >
        <span class="w-full">No stampDuty requests at this moment...</span>
      </p>
    </div>
    <div v-else>
      <div class="w-full sm:flex sm:items-center sm:justify-between">
        <button
          v-show="unit === 'chairman' && role === 'director'"
          type="button"
          @click="authorizeAll"
          class="w-full sm:w-auto shadow-sm bg-green-500 sm:rounded-md rounded-md mb-4 mt-6 px-3 py-1 text-white text-center font-medium focus:outline-none"
        >
          <div v-if="!authorizingAll">Authorize all</div>
          <div v-else class="flex items-center space-x-4 justify-center">
            <i class="fas fa-circle-notch animate-spin"></i>
            <span>Please wait...</span>
          </div>
        </button>
        <div class="flex items-center bg-gray-50 px-2 py-2 shadow-sm font-bold">
          <p>Tax due : ₦{{ totalStampDutyTaxDue }}</p>
        </div>
      </div>
      <div class="mx-0 sm:mx-0 overflow-auto hide-scrollbar mt-6 sm:mt-0">
        <div class="py-2 align-middle inline-block min-w-full">
          <div
            class="shadow overflow-auto hide-scrollbar border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full table-striped">
              <thead>
                <tr class="text-xs">
                  <th
                    v-show="unit === 'chairman' && role === 'director'"
                    class="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-700 tracking-wider"
                  >
                    Action
                  </th>
                  <th
                    class="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-700 tracking-wider"
                  >
                    TIN
                  </th>
                  <th
                    class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider"
                  >
                    Assignor
                  </th>
                  <th
                    class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider"
                  >
                    Type
                  </th>

                  <th
                    class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider"
                  >
                    Rate
                  </th>
                  <th
                    class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider"
                  >
                    Date
                  </th>
                  <th
                    class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider"
                  >
                    Amount
                  </th>
                  <th
                    class="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-gray-700 tracking-wider"
                  >
                    Invoice No
                  </th>

                  <th class="px-6 py-3 border-b-2 border-gray-300 hidden"></th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <tr
                  class="cursor-pointer hover:bg-gray-100 hover:font-semibold"
                  v-for="stampDuty in stampDutyRequests"
                  :key="stampDuty.id"
                >
                  <td
                    v-show="unit === 'chairman' && role === 'director'"
                    class="pr-6 py-4 whitespace-nowrap text-right text-sm font-medium border-b-2 border-dashed border-gray-200"
                  >
                    <div class="flex items-center space-x-4">
                      <button
                        type="button"
                        @click="authorize(stampDuty.id)"
                        class="bg-green-500 rounded-md ml-2 px-3 py-1 text-white text-center font-medium focus:outline-none"
                      >
                        <div v-if="stampDutyId != stampDuty.id">Authorize</div>
                        <div
                          v-else
                          class="flex items-center space-x-4 justify-center"
                        >
                          <i class="fas fa-circle-notch animate-spin"></i>
                          <span>Please wait...</span>
                        </div>
                      </button>
                    </div>
                  </td>
                  <td
                    class="px-6 whitespace-no-wrap border-b-2 border-dashed border-gray-200"
                  >
                    <div class="text-sm leading-5 text-gray-800 py-4">
                      {{ stampDuty.assignee.tin }}
                    </div>
                  </td>
                  <td
                    class="px-6 whitespace-no-wrap border-b-2 border-dashed border-gray-200"
                  >
                    <div class="text-sm leading-5 text-gray-700 w-56 sm:w-54">
                      {{ stampDuty.assignor.name }}
                    </div>
                  </td>
                  <td
                    class="px-6 whitespace-no-wrap border-b-2 border-dashed border-gray-200"
                  >
                    <div class="text-sm leading-5 text-gray-700 w-56 sm:w-54">
                      {{ stampDuty.rate.name }}
                    </div>
                  </td>

                  <td
                    class="px-6 whitespace-no-wrap border-b-2 border-dashed border-gray-200 text-gray-700 text-sm leading-5"
                  >
                    <div class="text-sm leading-5 text-gray-700 w-32 sm:w-36">
                      {{ formatStampDutyRate(stampDuty.rate) }}
                    </div>
                  </td>
                  <td
                    class="px-6 w-36 whitespace-no-wrap border-b-2 border-dashed border-gray-200 text-gray-700 text-sm leading-5"
                  >
                    <div class="text-sm leading-5 text-gray-700 w-44 sm:w-34">
                      {{ formatDate(stampDuty.createdAt) }}
                    </div>
                  </td>
                  <td
                    class="px-6 w-32 md:w-28 whitespace-no-wrap border-b-2 border-dashed border-gray-200 text-gray-700 text-sm leading-5"
                  >
                    <div class="text-sm leading-5 text-gray-700 w-32 md:w-28">
                      ₦ {{ formatAmount(stampDuty.total) }}
                    </div>
                  </td>

                  <td
                    class="px-6 whitespace-no-wrap border-b-2 border-dashed border-gray-200 text-gray-700 text-sm leading-5"
                  >
                    <button
                      @click="showInvoiceDetails(stampDuty.invoice_number)"
                      class="outline-none underline cursor-pointer hover:font-medium"
                    >
                      {{ stampDuty.invoice_number }}
                    </button>
                  </td>

                  <td
                    class="px-6 hidden whitespace-no-wrap border-b-2 border-dashed border-gray-200 text-right text-sm leading-5"
                  >
                    <!-- <button
                  v-show="stampDuty.stampDuty_type.toLowerCase() !== 'paye'"
                  @click.prevent="printBill(stampDuty)"
                  :disabled="
                    stampDuty.status === 'verified' || preparingNotice
                      ? true
                      : false
                  "
                  class="px-2
                   py-2  rounded transition duration-300  focus:outline-none border-2"
                  :class="
                    `${
                      stampDuty.status === 'verified'
                        ? 'border-gray-300 text-gray-300'
                        : 'border-blue-500  text-blue-500 hover:bg-blue-700 hover:text-white'
                    }`
                  "
                >
                  <svg
                    v-if="stampDutyId !== stampDuty.id"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <i
                    v-else
                    :class="
                      `lg:focus:shadow-sm w-5 text-center fas fa-circle-notch text-lg animate-spin`
                    "
                  ></i>
                </button> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </content-holder>
</template>

<script>
import ContentHolder from "../../ContentHolder.vue";
import numeral from "numeral";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { unit, role } from "../../../helpers/login_details";
export default {
  components: { ContentHolder },
  data: () => ({
    loadingData: true,
    authorizingAll: false,
    authorizingSingle: false,
    stampDutyInterval: null,
    stampDutyId: "",
    unit,
    role,
  }),
  computed: {
    stampDutyRequests() {
      return this.$store.getters["stamp_duty/duties"];
    },
    totalStampDutyTaxDue() {
      let total = 0;

      this.$store.getters["stamp_duty/duties"].forEach((d) => {
        total += numeral(d.total)._value;
      });

      return numeral(total).format("0,0.00");
    },
  },
  methods: {
    formatAmount(amt) {
      return numeral(amt).format("0,0.00");
    },
    formatDate(dt) {
      return dayjs(dt).format("DD-MMM-YYYY h:mm:ss a");
    },
    formatStampDutyRate(duty) {
      return `${duty.type == "flat" ? "N" : ""} ${
        duty.type == "flat" ? this.formatAmount(duty.rate) : duty.rate
      }  ${duty.type == "flat" ? "" : "%"}`;
    },
    authorize(id) {
      this.authorizingSingle = true;
      this.stopFetchingStampDuties();
      this.stampDutyId = id;
      this.$store
        .dispatch("stamp_duty/updateSingleStampDuty", { ids: [id] })
        .then(() => {
          this.authorizingSingle = false;
          this.reqId = 0;
          // Swal.fire({
          //     icon: "success",
          //     title: "Revenue House",
          //     text: "Stamp Duty request approved!!!",
          //   });
          setTimeout(() => {
            this.startFetchingStampDuties();
          }, 3000);
        })
        .catch((err) => {
          if (
            !err.includes("Network Error") &&
            !err.toLowerCase().includes("timeout")
          ) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err,
            });
          }
          this.reqId = 0;
          this.authorizingSingle = false;
        })
        .finally(() => {
          this.authorizingSingle = false;
          this.stampDutyId = "";
        });
    },
    authorizeAll() {
      this.authorizingAll = true;
      this.stopFetchingStampDuties();
      let ids = [];
      this.stampDutyRequests.forEach((r) => {
        ids.push(r.id);
      });

      this.$store
        .dispatch("stamp_duty/updateAllStampDuties", { ids: [...ids] })
        .then(() => {
          this.authorizingAll = false;
          Swal.fire({
            icon: "success",
            title: "Revenue House",
            text: "All Stamp Duty requests approved!!!",
          });
          setTimeout(() => {
            this.startFetchingStampDuties();
          }, 3000);
        })
        .catch((err) => {
          if (
            !err.includes("Network Error") &&
            !err.toLowerCase().includes("timeout")
          ) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err,
            });
          }
          this.authorizingAll = false;
        })
        .finally(() => {
          this.authorizingAll = false;
        });
    },
    fetchStampDuties() {
      this.$store
        .dispatch("stamp_duty/getDuties", {
          status: "approved",
          from: "authorize",
        })
        .then(() => {
          this.loadingData = false;
        })
        .catch((err) => {
          if (
            !err.includes("Network Error") &&
            !err.toLowerCase().includes("timeout") &&
            !err.toLowerCase().includes("no record found")
          ) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err,
            });
          }
          this.loadingData = false;
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    async stopFetchingStampDuties() {
      await clearInterval(this.stampDutyInterval);
      //  console.log("unmounted");
    },
    startFetchingStampDuties() {
      this.stampDutyInterval = setInterval(() => {
        this.fetchStampDuties();
      }, 20000);
    },
  },
  created() {
    this.loadingData = true;

    this.fetchStampDuties();
  },
  mounted() {
    this.startFetchingStampDuties();
  },

  beforeUnmount() {
    this.stopFetchingStampDuties();
  },
};
</script>

<style></style>

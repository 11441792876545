<template>
  <div v-if="canViewStampDuty" class="h-full flex flex-col overflow-y-auto">
    <template v-if="unit === 'stampduty' || unit === 'administrator'">
      <div class="w-full" style="border-bottom: 0px solid #eaeaea">
        <ul class="flex cursor-pointer">
          <li
            v-for="tab in stamp_duty_tabs"
            :key="tab"
            @click="selectedTab = tab"
            class="transition w-full btnTab duration-300 ease-in-out py-2 px-6 text-blue-primary text-center"
            :class="`${
              selectedTab !== tab ? 'border-gray-300' : 'border-blue-primary '
            }`"
          >
            {{ tab }}
          </li>
          <!-- <li class='py-2 px-6 bg-white rounded-t-lg  '>Akun</li> -->
        </ul>
      </div>
      <div class="mt-6">
        <transition name="fade">
          <keep-alive>
            <component :is="selectedTab"></component>
          </keep-alive>
        </transition>
      </div>
    </template>

    <content-holder
      v-else-if="unit === 'finance' && role === 'director'"
      :title="'Stamp Duty'"
      class="p-6"
    >
      <approve-stamp-duty></approve-stamp-duty>
    </content-holder>
    <content-holder
      v-else-if="unit === 'chairman'"
      :title="'Stamp Duty'"
      class="p-6"
    >
      <authorize-stamp-duty></authorize-stamp-duty>
    </content-holder>
  </div>
  <content-holder v-else :title="'Stamp Duty'" class="p-4">
    <unauthorized-access></unauthorized-access>
  </content-holder>
</template>

<script>
import ContentHolder from "../components/ContentHolder.vue";
import UnauthorizedAccess from "@/components/shared/UnauthorizedAccess.vue";
import AuthorizeStampDuty from "../components/stamp_duty/admin/AuthorizeStampDuty.vue";
import ApproveStampDuty from "../components/stamp_duty/admin/ApproveStampDuty.vue";
import New from "../components/stamp_duty/NewStampDuty.vue";
import Search from "../components/stamp_duty/SearchStampDuty.vue";
import Verify from "@/components/stamp_duty/admin/VerifyStampDuty.vue";
import { views, unit, role } from "@/helpers/login_details.js";
// import { useStore } from "vuex";
import { ref, onMounted } from "vue";
export default {
  components: {
    New,
    Search,
    Verify,
    ContentHolder,
    ApproveStampDuty,
    AuthorizeStampDuty,
    UnauthorizedAccess,
  },
  data: () => ({
    unit,
    role,
  }),
  setup() {
    const stamp_duty_tabs = ref(["New", "Search", "Verify"]);
    const canViewStampDuty = ref(false);
    const selectedTab = ref(stamp_duty_tabs.value[0]);

    onMounted(() => {
      if (views.includes("StampDuty")) {
        canViewStampDuty.value = true;

        if (role == "basic") {
          stamp_duty_tabs.value.pop();
        }
      } else {
        canViewStampDuty.value = false;
      }
    });

    return {
      canViewStampDuty,
      stamp_duty_tabs,
      selectedTab,
    };
  },
};
</script>

<style></style>
